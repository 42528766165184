import type { Product } from "~/models/product";
import type { Service } from "~/models/service";
import type { Event } from "~~/models/event";
import type { PublicMedia } from "~~/modules/sales/src";

export enum ObjectType {
  Product = "product",
  Service = "service",
  Event = "event",
  Recipe = "recipe",
  BusinessProfile = "business_profile",
}
// export type ObjectType =
//   | "UserList"
//   | "Order"
//   | "Category"
//   | "Collection"
//   | "PublishedProduct"
//   | "PublishedEvent"
//   | "Recipe"
//   | "BusinessProfile"
//   | "EventProfile"
//   | "ServiceProfile"
//   | "Article"
//   | "Blog"
//   | "Policy"
//   | "Faq";

export interface SearchResult {
  id: number | string;
  object: ObjectType;
  gid: string;
  name?: string;
  created_at: number;
}

export interface EventTicketResult {
  name: string;
  type: string;
  description?: string;
  price: number;
  presentment_order: number;
  private: boolean;
  disabled: boolean;
}

export interface EventVenueResult {
  name: string;
  description?: string;
  address_line?: string;
  street?: string;
  street_extra?: string;
  province?: string;
  postal_code?: string;
  country_code?: string;
  latitude?: number;
  longitude?: number;
}

export type ProductResult = SearchResult & Product;

export type EventResult = SearchResult & Event;

export type ServiceResult = SearchResult & Service;

export interface RecipeVariantResult extends SearchResult {
  slug: string;
  name: string;
  image?: PublicMedia;
  description?: string;
  published: boolean;
  prep_time: number;
  cook_time: number;
  serves: number;
  difficulty: number; // 0-5
  nationality?: string;
  nutrition?: string;
  attribution?: string;
}

export interface RecipeResult extends SearchResult {
  handle: string;
  name: string;
  image?: PublicMedia;
  description?: string;
  published: boolean;
  prep_time: number;
  cook_time: number;
  serves: number;
  difficulty: number; // 0-5
  nationality?: string;
  attribution: string[];
  cuisine?: string;
  variants: RecipeVariantResult[];
}

export interface BusinessProfileResult extends SearchResult {
  handle: string;
  name: string;
  type: string;
  image?: PublicMedia;
  nationality?: string;
}
