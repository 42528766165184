import { debounce, get, last, set } from "lodash";
import defu from "defu";
import { MeiliSearch } from "meilisearch";
import type {
  Crop,
  Highlight,
  Index,
  MatchingStrategies,
  SearchResponse,
} from "meilisearch";
import { useLocalStorage } from "@vueuse/core";
import type { SearchResult } from "~~/components/elements/search/models";

type SearchConfig<C extends Record<string, any>, P, PK extends keyof P> = {
  host: string;
  clients: C;
  searchOptions?: SearchOptions &
    Crop & {
      hitsPerPage?: number;
    };
  defaultPreset: PK;
  presets: P;
};

type Presets<C> = Record<
  string,
  {
    client: keyof C;
    index?: string;
    searchOptions?: SearchOptions;
  }
>;

type SearchOptions = Highlight & {
  filter?: string;
  sort?: string[];
  facets?: string[];
  attributesToRetrieve?: string[];
  showMatchesPosition?: boolean;
  matchingStrategy?: MatchingStrategies;
};

const useHistory = (indexName: string) => {
  const history = useLocalStorage<string[]>(`search-history:${indexName}`, []);
  const size = 4;

  const track = debounce((text: string) => {
    if (text) {
      const h = [text, ...history.value.filter((e) => e !== text)];

      if (h.length > size) {
        history.value = h.slice(0, size);
      } else {
        history.value = h;
      }
    }
  }, 1000);

  const getLastSearch = () => {
    return last(history.value);
  };

  return {
    history,
    track,
    getLastSearch,
  };
};

const useSearchIndex = <T extends SearchResult>(
  index: Index<T>,
  options: SearchOptions,
  presetName: string
) => {
  const state = reactive<
    SearchResponse<T> & {
      loading: boolean;
    }
  >({
    hits: [],
    processingTimeMs: 0,
    query: "",
    loading: false,
  });

  const offset = ref(0);
  const limit = ref(20);
  const page = ref<number>();
  const hitsPerPage = ref<number>();
  const filter = ref<string>();
  const sort = ref<string[]>();
  const facets = ref<string[]>();

  // const suggested = ref<string[]>([]);
  // const top = ref<string[]>([]);
  const { history, track, getLastSearch } = useHistory(presetName);

  const _search = async (query: string) => {
    track(query);
    state.loading = true;

    let _filter: string | undefined,
      _sort: string[] | undefined,
      _facets: string[] | undefined;

    if (sort.value || options?.sort) {
      _sort = [...(sort.value || []), ...(options?.sort || [])];
    }
    if (facets.value || options?.facets) {
      _facets = [...(facets.value || []), ...(options?.facets || [])];
    }
    if (filter.value && options?.filter) {
      _filter = `(${filter.value}) AND (${options.filter})`;
    } else {
      _filter = filter.value || options?.filter;
    }

    const result = await index
      .search<T>(query, {
        ...options,
        filter: _filter,
        sort: _sort,
        facets: _facets,
        offset: offset.value,
        limit: limit.value,
        hitsPerPage: hitsPerPage.value,
        page: page.value,
      })
      .finally(() => {
        state.loading = false;
      });

    Object.assign(state, result);
  };

  const search: typeof _search = debounce(_search, 200) as any;

  const refresh = () => {
    const lastQuery = getLastSearch();
    search(lastQuery || "");
  };

  watch([offset, limit, filter, sort, facets], refresh);

  return {
    search,
    state,
    offset,
    limit,
    page,
    hitsPerPage,
    filter,
    sort,
    facets,
    history,
  };
};

export const useSearchApi = <
  C extends Record<string, any>,
  P extends Presets<C> = Presets<C>,
  PK extends keyof P = keyof P
>(
  config: SearchConfig<C, P, PK>
) => {
  type SearchIndex = ReturnType<typeof useSearchIndex>;

  const clients: Record<string, MeiliSearch> = {};
  const presets: Record<PK, SearchIndex> = {} as Record<PK, SearchIndex>;
  const preset = ref<PK>(config.defaultPreset);

  const getActiveSearch = (): SearchIndex => {
    return get(presets, preset.value);
  };

  const getStateValue = <
    K extends keyof SearchIndex["state"],
    V extends SearchIndex["state"][K]
  >(
    k: K,
    defu?: V
  ): V => {
    return (getActiveSearch().state[k] as any) || defu;
  };

  for (const k in config.clients) {
    const client = new MeiliSearch({
      host: config.host,
      apiKey: config.clients[k],
    });
    set(clients, k, client);
  }

  for (const p in config.presets) {
    const conf = config.presets[p] as unknown as P[PK];
    const indexName = conf.index || p;
    const client = get(clients, conf.client);
    const index = client?.index(indexName);
    if (index) {
      const searchIndex = useSearchIndex(
        index,
        defu(conf.searchOptions, config.searchOptions),
        p
      );
      set(presets, p, searchIndex);
    }
  }

  watch(preset, () => {
    const searchIndex = getActiveSearch();
    if (searchIndex?.state?.query !== _q.value) {
      searchIndex.search(_q.value || "");
    }
  });

  const estimatedTotalHits = computed(() =>
    getStateValue("estimatedTotalHits")
  );
  const facetDistribution = computed(() =>
    getStateValue("facetDistribution", {})
  );
  const facetStats = computed(() => getStateValue("facetStats", {}));
  const hits = computed(() => getStateValue("hits"));
  const processingTimeMs = computed(() => getStateValue("processingTimeMs"));
  const totalHits = computed(() => getStateValue("totalHits", 0));
  const totalPages = computed(() => getStateValue("totalPages", 0));
  const loading = computed(() => getStateValue("loading", false));

  const _q = ref<string>();

  const query = computed<string | undefined>({
    set(value: string | undefined) {
      const { search } = getActiveSearch();
      search(value || "");
      _q.value = value;
    },
    get(): string | undefined {
      return _q.value;
    },
  });

  const limit = computed<number>({
    set(value: number) {
      const searchIndex = getActiveSearch();
      searchIndex.limit.value = Number(value || 0);
    },
    get(): number {
      const searchIndex = getActiveSearch();
      return searchIndex.limit.value;
    },
  });

  const offset = computed<number>({
    set(value: number) {
      const { offset } = getActiveSearch();
      offset.value = Number(value || 0);
    },
    get(): number {
      const searchIndex = getActiveSearch();
      return searchIndex.offset.value;
    },
  });

  const page = computed<number | undefined>({
    set(value: number | undefined) {
      const searchIndex = getActiveSearch();
      searchIndex.page.value = Number(value || 0);
    },
    get(): number | undefined {
      const searchIndex = getActiveSearch();
      return searchIndex.page.value;
    },
  });

  const hitsPerPage = computed<number | undefined>({
    set(value: number | undefined) {
      const searchIndex = getActiveSearch();
      searchIndex.hitsPerPage.value = Number(value || 0);
    },
    get(): number | undefined {
      const searchIndex = getActiveSearch();
      return searchIndex.hitsPerPage.value;
    },
  });

  const filter = computed<string | undefined>({
    set(value: string | undefined) {
      const searchIndex = getActiveSearch();
      searchIndex.filter.value = value;
    },
    get(): string | undefined {
      const searchIndex = getActiveSearch();
      return searchIndex.filter.value;
    },
  });

  const sort = computed<string[] | undefined>({
    set(value: string[] | undefined) {
      const searchIndex = getActiveSearch();
      searchIndex.sort.value = value;
    },
    get(): string[] | undefined {
      const searchIndex = getActiveSearch();
      return searchIndex.sort.value;
    },
  });

  const facets = computed<string[] | undefined>({
    set(value: string[] | undefined) {
      const searchIndex = getActiveSearch();
      searchIndex.facets.value = value;
    },
    get(): string[] | undefined {
      const searchIndex = getActiveSearch();
      return searchIndex.facets.value;
    },
  });

  const history = computed(() => {
    const searchIndex = getActiveSearch();
    return searchIndex.history.value;
  });

  const search = (q: string) => {
    query.value = q;
  };

  return {
    estimatedTotalHits,
    facetDistribution,
    facetStats,
    hits,
    hitsPerPage,
    limit,
    offset,
    page,
    filter,
    sort,
    facets,
    history,
    processingTimeMs,
    totalHits,
    totalPages,
    loading,
    preset,
    query,
    search,
  };
};
