<template>
  <v-card
    v-bind="$props"
    class="wrapper"
    :height="size"
    :width="size"
    :style="{ backgroundColor }"
  >
    <v-img
      :height="size"
      :width="size"
      :src="image.medium"
      :lazySrc="image.xsmall"
    />
  </v-card>
</template>

<script>
import { VCard } from "vuetify/components";
export default {
  name: "ImageObjectField",
  props: {
    ...VCard.props,
    image: { type: Object, required: true },
    size: { type: String | Number, default: "40px" },
    href: { type: String, default: undefined },
    backgroundColor: { type: String, default: undefined },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  border-radius: 5px;
  overflow: hidden;
}
.round {
  border-radius: 50% !important;
}
</style>
