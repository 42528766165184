import type { WritableComputedRef } from "vue";
import { defineStore } from "pinia";
import { useLocalStorage } from "@vueuse/core";
import { useTheme } from "vuetify";

export type ThemeType = "light" | "dark";
export type ThemeTypeOptions = ThemeType | "system";

export const useThemeStore = defineStore("theme", () => {
  const appConfig = useAppConfig();

  const getCurrentSystemTheme = (): ThemeType => {
    if (import.meta.server) {
      if (appConfig.theme.theme == "system") return "light";
      return appConfig.theme.theme;
    }
    const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
    return darkThemeMq.matches ? "dark" : "light";
  };

  const vuetifyTheme = useTheme();

  const theme = useLocalStorage<ThemeTypeOptions>(
    "theme",
    appConfig.theme.theme
  );
  const systemTheme = ref<ThemeType>(getCurrentSystemTheme());
  const rtl = ref(appConfig.theme.rtl);
  // const favicon = useFavicon();

  const setTheme = (value: ThemeTypeOptions) => {
    theme.value = value;
    vuetifyTheme.global.name.value = computedTheme.value;
    // favicon.value = computedTheme.value == 'light'? 'favicon.ico' : 'icon-dark.png'
  };
  const setDark = (dark = true) =>
    dark ? setTheme("dark") : setTheme("light");
  const setSystem = () => setTheme("system");

  if (!import.meta.server) {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (event) => {
        systemTheme.value = event.matches ? "dark" : "light";
      });
  }

  const computedTheme = computed<ThemeType>(() => {
    if (theme.value == "system") {
      return systemTheme.value;
    }
    return theme.value;
  });

  const $theme: WritableComputedRef<ThemeTypeOptions> = computed({
    get: () => theme.value,
    set: (value) => setTheme(value),
  });

  const isDark = computed(() => computedTheme.value === "dark");

  vuetifyTheme.global.name.value = computedTheme.value;

  return {
    theme: $theme,
    themeValue: readonly(theme),
    rtl,
    isDark,
    setTheme,
    setDark,
    setSystem,
  };
});
