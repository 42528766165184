<template>
  <div v-bind="$attrs" class="desktop-search-input">
    <v-overlay
      v-model="focused"
      class="desktop-search-input-overlay z-[2402]-"
      :scrim="false"
      persistent
      width="100%"
    >
      <div class="desktop-search-input-overlay__scrim"></div>
      <v-row class="w-full" justify="center" no-gutters>
        <v-card
          class="mt-1"
          width="520"
          rounded="lg"
          v-click-outside="close"
          elevation="5"
        >
          <v-row class="w-full" justify="center" no-gutters>
            <div>
              <v-responsive width="500">
                <v-text-field
                  ref="input"
                  v-model="queryModel"
                  :placeholder="placeholder"
                  class="flat-text-field mt-1 rounded-pill overflow-hidden"
                  variant="filled"
                  density="comfortable"
                  prepend-inner-icon="search"
                  autofocus
                  hide-details
                />
              </v-responsive>
            </div>
          </v-row>
          <v-tabs
            v-model="presetModel"
            class="mt-2"
            density="compact"
            height="30"
            mandatory
            center-active
          >
            <v-tab
              v-for="preset in categories"
              :key="preset.value"
              :value="preset.value"
              :ripple="false"
            >
              <small>{{ preset.text }}</small>
            </v-tab>
          </v-tabs>
          <v-divider class="mx-2" />
          <slot></slot>
        </v-card>
      </v-row>
    </v-overlay>
    <v-text-field
      :placeholder="defaultPlaceholder"
      @focus="handleFocus"
      class="flat-text-field rounded-pill overflow-hidden"
      variant="filled"
      density="comfortable"
      prepend-inner-icon="search"
      hide-details
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { SearchCategory, getPlaceholder, searchCategories } from "./lib";
import mousetrap from "mousetrap";
import { useDesktopSearchStore } from "~~/stores/desktop-search";
import { storeToRefs } from "pinia";

export default defineComponent({
  name: "DesktopSearchInput",
  props: {
    query: { type: String },
    preset: {
      type: String as PropType<SearchCategory>,
      default: SearchCategory.PRODUCTS,
    },
  },
  setup(props, context) {
    const propRefs = toRefs(props);
    const input = ref<HTMLElement>();
    const opening = ref(false);
    const port = ref();
    const shortcut = "/";

    const store = useDesktopSearchStore();
    const { visible: focused } = storeToRefs(store);

    const queryModel = computed({
      set(value: string | undefined) {
        context.emit("update:query", value);
      },
      get(): string | undefined {
        return propRefs.query.value;
      },
    });

    const presetModel = computed({
      set(value: SearchCategory) {
        context.emit("update:preset", value);
      },
      get(): SearchCategory {
        return propRefs.preset.value;
      },
    });

    const register = () =>
      mousetrap.bind(shortcut, ($event: Event) => {
        $event.preventDefault();
        handleFocus();
      });

    const handleFocus = () => {
      opening.value = true;
      input.value?.focus();
      focused.value = true;
      setTimeout(() => (opening.value = false), 1000);
    };

    const close = () => {
      if (!opening.value) {
        focused.value = false;
      }
    };

    const defaultPlaceholder = `Hit "${shortcut}" to start searching`;

    const placeholder = computed(() =>
      focused.value ? getPlaceholder(presetModel.value) : defaultPlaceholder
    );

    onMounted(register);

    return {
      input,
      placeholder,
      defaultPlaceholder,
      handleFocus,
      close,
      focused,
      port,
      queryModel,
      presetModel,
    };
  },
  data() {
    return {
      categories: searchCategories,
    };
  },
});
</script>

<style lang="scss">
.desktop-search-input-overlay__scrim {
  position: absolute;
  // top: 66px;
  // height: calc(100vh - 66px);
  top: 0;
  height: 100vh;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.2) !important;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}
</style>
