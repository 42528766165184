export enum SearchCategory {
  PRODUCTS = "products",
  SERVICES = "services",
  GROCERIES = "groceries",
  EVENTS = "events",
  RECIPES = "recipes",
  FASHION = "fashion",
  RESTAURANTS = "restaurants",
  SHOPS = "shops",
  COMMUNITIES = "communities",
}

export const getPlaceholder = (category: SearchCategory) =>
  match(category, {
    [SearchCategory.SERVICES]: "Search services",
    [SearchCategory.GROCERIES]: "Search groceries",
    [SearchCategory.EVENTS]: "Search local events",
    [SearchCategory.RECIPES]: "Search popular African recipes",
    [SearchCategory.SHOPS]: "Search local shops & businesses",
    [SearchCategory.FASHION]: "Search fashion & clothing",
    [SearchCategory.RESTAURANTS]: "Search restaurants",
    [SearchCategory.COMMUNITIES]: "Search african communities and associations",
    default: "Search products, events, recipes, shops",
  });

export const searchCategories = [
  { text: "Products", value: SearchCategory.PRODUCTS },
  { text: "Services", value: SearchCategory.SERVICES },
  // { text: "Groceries", value: SearchCategory.GROCERIES },
  { text: "Events", value: SearchCategory.EVENTS },
  { text: "Recipes", value: SearchCategory.RECIPES },
  // { text: "Fashion", value: SearchCategory.FASHION },
  // { text: "Restaurants", value: SearchCategory.RESTAURANTS },
  { text: "Shops", value: SearchCategory.SHOPS },
  { text: "Communities", value: SearchCategory.COMMUNITIES },
];
