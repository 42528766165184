<template>
  <div>
    <div>Recipe</div>
    {{ hit }}
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { RecipeResult } from "../models";

export default defineComponent({
  name: "SearchContentRecipeItem",
  props: {
    hit: { type: Object as PropType<RecipeResult>, required: true },
  },
});
</script>
