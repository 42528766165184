import type { Hit } from "meilisearch";
import { ObjectType, type SearchResult } from "../models";
import Product from "./Product.vue";
import Service from "./Service.vue";
import Event from "./Event.vue";
import Recipe from "./Recipe.vue";
import BusinessProfile from "./BusinessProfile.vue";

export default defineComponent({
  name: "SearchContentItem",
  props: {
    hit: { type: Object as PropType<Hit<SearchResult>>, required: true },
  },
  render() {
    const component = match(this.hit.object, {
      [ObjectType.Product]: Product,
      [ObjectType.Service]: Service,
      [ObjectType.Event]: Event,
      [ObjectType.Recipe]: Recipe,
      [ObjectType.BusinessProfile]: BusinessProfile,
    });

    if (component) {
      return h(component, { hit: this.hit });
    }
  },
});
