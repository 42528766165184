<template>
  <v-list-item :title="hit.name" :to="url">
    <template #prepend>
      <image-field
        :src="hit.image"
        width="80"
        aspect-ratio="1"
        class="mr-2"
        fit="cover"
      />
    </template>
    <template #subtitle>
      <span v-if="hit.store" class="text-caption">
        By
        <router-link
          :to="{
            name: 's-handle',
            params: { handle: hit.store.handle },
          }"
        >
          {{ hit.store.name }}
        </router-link>
      </span>
    </template>
    <div>
      <div class="text-caption text-3.5 flex items-center">
        <span>{{ duration }}</span>
        <template v-if="gender">
          <span class="mx-1">·</span>
          <span>{{ gender }} only</span>
        </template>
        <template v-if="age">
          <span class="mx-1">·</span>
          <v-chip size="15" class="px-1" color="red" variant="flat" label>
            <small>{{ age }}</small>
          </v-chip>
        </template>
      </div>
      <div class="text-3.5">
        <span v-if="hit.price_min < hit.price">
          From {{ formatPrice(hit.price_min) }}
        </span>
        <span v-else>{{ formatPrice(hit.price) }}</span>
      </div>
    </div>
  </v-list-item>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ImageField from "~~/components/fields/ImageField.vue";
import EventDateTime from "~~/components/elements/event/EventDateTime";
import type { ServiceResult } from "../models";

export default defineComponent({
  name: "SearchContentServiceItem",
  components: { ImageField, EventDateTime },
  props: {
    hit: { type: Object as PropType<ServiceResult>, required: true },
  },
  setup(props) {
    const use = useService(props.hit);
    return use;
  },
  computed: {
    url() {
      return {
        name: "services-provider-service",
        params: {
          provider: this.hit.serviceProfile.handle,
          service: this.hit.handle,
        },
      };
    },
  },
  methods: {
    formatPrice,
  },
});
</script>
