<template>
  <logo v-bind="{ ...$props, ...themeProps }" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useThemeStore } from "~~/stores/theme";
import Logo from "~~/components/global/Logo.vue";

export default defineComponent({
  name: "ThemedLogo",
  components: { Logo },
  props: {
    ...Logo.props,
    dark: { type: Boolean, default: false },
  },
  setup(props) {
    const propsRef = toRefs(props as { dark: boolean; color: string });
    const theme = useThemeStore();

    const themeProps = computed(() => {
      const props: any = {
        color: propsRef.color.value,
      };

      if (theme.isDark) {
        props.lucid = true;
        // props.color = 'white'
      }
      return props;
    });

    return {
      themeProps,
    };
  },
});
</script>
