import * as pluralize from "pluralize";

/**
 * Pluralise text based on number
 *
 * @param num
 * @param text
 * @param textPlural
 * @returns
 */
export const plurable = (
  num: number,
  text: string,
  textPlural?: string
): string => {
  if (num == 1) return text;
  if (textPlural) return textPlural;
  return pluralize.plural(text);
};
