import { defineStore } from "pinia";

export const useDesktopSearchStore = defineStore("desktop-search", () => {
  const visible = ref(false);

  const toggleVisible = () => {
    visible.value = !visible.value;
  };

  return {
    visible,
    toggleVisible,
    //
  };
});
