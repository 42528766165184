<template>
  <desktop-search-input v-model:query="query" v-model:preset="preset">
    <v-sheet max-height="400" style="overflow-y: auto">
      <search-content
        class="search-content-item-2"
        :query="query"
        :hits="hits"
        :preset="preset"
        :recent-searches="history"
        :loading="loading"
        :search="search"
      />
    </v-sheet>
  </desktop-search-input>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DesktopSearchInput from "./DesktopSearchInput.vue";
import SearchContent from "./SearchContent.vue";

export default defineComponent({
  name: "DesktopSearch",
  components: { DesktopSearchInput, SearchContent },
  setup() {
    const search = useSearch();

    return search;
  },
});
</script>
