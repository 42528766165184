<template>
  <v-badge
    ref="handle"
    :content="itemsCount"
    :model-value="!isEmpty"
    :bottom="mobile"
    :theme="theme"
    offset-x="4"
    offset-y="5"
    color="primary"
    bordered
    overlap
  >
    <v-btn v-bind="$props" :theme="theme" icon="local_mall" overlap />
  </v-badge>
  <div ref="cartContent" style="display: none">
    <v-sheet :theme="theme" width="250" rounded="lg" class="overflow-hidden">
      <v-card-text v-if="loadingCart">
        <div class="pa-5 text-center">
          <v-progress-circular indeterminate width="1" />
        </div>
      </v-card-text>
      <v-card-text v-else-if="isEmpty">
        <div class="text-center px-10 py-5">Your Cart is Empty.</div>
      </v-card-text>
      <template v-else>
        <v-list dense>
          <v-list-item
            v-for="item in items"
            :key="`item-${item.id}`"
            :title="item.name"
          >
            <template #prepend>
              <span v-if="item.image" class="mr-4">
                <v-badge
                  overlap
                  :content="item.quantity"
                  :model-value="item.quantity > 1"
                  max="10"
                >
                  <v-avatar size="50" rounded="lg">
                    <media-block
                      :content="{
                        media: item.image,
                        imageOptions: {
                          fit: 'contain',
                        },
                      }"
                    />
                  </v-avatar>
                </v-badge>
              </span>
            </template>
            <template #subtitle>
              {{ currency.symbol }}{{ item.total }}
              <template v-if="item.options.length">
                <span class="mx-1">|</span>
                <span v-for="(option, i) in item.options" :key="option.key">
                  <template v-if="i">,</template> {{ option.value }}
                </span>
              </template>
            </template>
          </v-list-item>
        </v-list>
        <v-divider />
        <div class="pa-2">
          <v-btn
            @click="toCheckout"
            color="primary"
            variant="flat"
            size="large"
            block
          >
            Checkout
          </v-btn>
        </div>
      </template>
      <v-list class="pt-0" density="compact">
        <template v-for="(link, i) in links" :key="`link-${i}`">
          <v-divider class="mx-2"></v-divider>
          <v-list-item :to="link.route" :target="link.blank ? 'blank' : ''">
            <template v-if="link.icon" v-slot:prepend>
              <v-icon :icon="link.icon" class="mr-3" size="small"></v-icon>
            </template>
            <v-list-item-title>{{ link.text }}</v-list-item-title>
            <sup><v-icon v-if="link.blank" size="x-small">launch</v-icon></sup>
          </v-list-item>
        </template>
      </v-list>
    </v-sheet>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { VBtn } from "vuetify/components";
import createTippy, { type Instance, type SingleTarget } from "tippy.js";
import { useTheme } from "vuetify";
import ImageObjectField from "~~/components/fields/ImageObjectField.vue";

export default defineComponent({
  name: "CartBtn",
  components: { ImageObjectField },
  props: {
    ...VBtn.props,
  },
  setup() {
    const handle = ref<any>();
    const cartContent = ref<HTMLElement>();
    let tippy: Instance;

    const { name: theme } = useTheme();

    const { currency } = useCurrency();
    const { items, loading, itemsCount, isEmpty, loadOnce, toCheckout } =
      useCart();

    const mobile = useMobile();

    watch(theme, (value) => tippy.setProps({ theme: value }));

    onMounted(() => {
      // @ts-ignore
      tippy = createTippy(handle.value.$el as SingleTarget, {
        content: () => {
          if (cartContent.value) {
            // clone content to allow using component multiple times
            cartContent.value.style.display = "block";
          }
          return cartContent.value;
        },
        arrow: true,
        placement: "bottom",
        animation: "shift-toward",
        theme: theme.value,
        interactive: true,
        hideOnClick: false,
        appendTo: () => document.getElementById("app"),
        onShow: () => loadOnce(),
      });
    });

    onUnmounted(() => tippy && tippy.destroy());

    const links = computed(() => [
      {
        text: "Orders",
        icon: "shopping_cart",
        blank: false,
        route: { name: "index" },
      },
      {
        text: "Saved items",
        icon: "favorite",
        blank: false,
        route: { name: "index" },
      },
      {
        text: "My Shops",
        icon: "storefront",
        blank: false,
        route: { name: "index" },
      },
      {
        text: "My Events",
        icon: "calendar_month",
        blank: false,
        route: { name: "index" },
      },
      // { text: "Messages", icon: "envelope-outlined", blank: false, route: { name: "index" } },
      // { text: "Account", icon: "user", blank: false, route: { name: "index" } },
    ]);

    return {
      handle,
      cartContent,
      currency,
      items,
      loadingCart: loading,
      isEmpty,
      itemsCount,
      mobile,
      theme,
      links,
      toCheckout,
    };
  },
});
</script>

<style lang="scss">
.tippy-box .tippy-content {
  padding: 0;
}
</style>
