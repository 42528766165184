import { ServiceKind } from "~/services/api-data/types";
import { SearchCategory } from "~~/components/elements/search/lib";

export const useSearch = () => {
  const config = useRuntimeConfig().public.meilisearch;
  const baseFields = ["id", "object", "gid"];

  const eventFields = [
    "name",
    "image",
    "handle",
    "summary",
    "description",
    "starts_at",
    "ends_at",
    "active",
    "private",
    "online",
    "currency_code",
    "price",
    "price_min",
    "price_max",
    "timezone",
    "event_manager",
    "event_type",
    "tickets",
    "venue",
  ];

  const serviceFields = [
    "name",
    "image",
    "handle",
    "published_at",
    "kind",
    "description",
    "tags",
    "currency_code",
    "price",
    "price_min",
    "price_max",
    "timezone",
    "gender",
    "age_restriction",
    "serviceProfile",
    "variants",
  ];

  const businessProfileFields = [
    "handle",
    "name",
    "image",
    "type",
    "nationality",
  ];

  const search = useSearchApi({
    host: config.host,
    clients: {
      sales: config.tokens.sales,
      user: config.tokens.user,
    },
    defaultPreset: SearchCategory.PRODUCTS,
    searchOptions: {
      attributesToHighlight: ["*"],
    },
    presets: {
      [SearchCategory.PRODUCTS]: {
        client: "sales",
        index: "orie_marketplace_products",
      },
      [SearchCategory.GROCERIES]: {
        client: "sales",
        index: "orie_marketplace_products",
        // searchOptions: {
        // filter: "category IN groceries",
        // },
      },
      [SearchCategory.SERVICES]: {
        client: "sales",
        index: "orie_marketplace_services",
        searchOptions: {
          filter: `kind != \"${ServiceKind.MEETING}\"`,
          attributesToRetrieve: [...baseFields, ...serviceFields],
        },
      },
      [SearchCategory.EVENTS]: {
        client: "sales",
        index: "orie_marketplace_events",
        searchOptions: {
          attributesToRetrieve: [...baseFields, ...eventFields],
        },
      },
      [SearchCategory.RECIPES]: {
        client: "sales",
        index: "recipes",
      },
      [SearchCategory.FASHION]: {
        client: "sales",
        index: "orie_marketplace_products",
        // searchOptions: {
        // filter: "category IN groceries",
        // },
      },
      [SearchCategory.RESTAURANTS]: {
        client: "sales",
        index: "business_profiles",
        searchOptions: {
          filter: "tags = Restaurant",
          attributesToRetrieve: [...baseFields, ...businessProfileFields],
        },
      },
      [SearchCategory.SHOPS]: {
        client: "sales",
        index: "business_profiles",
        searchOptions: {
          filter: "type IN [Company, Individual]",
          attributesToRetrieve: [...baseFields, ...businessProfileFields],
        },
      },
      [SearchCategory.COMMUNITIES]: {
        client: "sales",
        index: "business_profiles",
        searchOptions: {
          filter: "tags = Community",
          attributesToRetrieve: [...baseFields, ...businessProfileFields],
        },
      },
    },
  });

  return search;
};
