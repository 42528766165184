<template>
  <div>
    <v-progress-linear v-if="loading && hits.length" height="1" indeterminate />
    <div v-if="hits.length">
      <template v-if="query">
        <div class="mx-4 my-2 text-caption">
          {{ hits.length }} {{ plurable(hits.length, "Result", "Results") }} for
          "{{ query }}"
        </div>
        <v-divider />
      </template>
      <v-list>
        <search-content-item v-for="hit in hits" :key="hit.gid" :hit="hit" />
      </v-list>
    </div>
    <div v-else-if="loading" class="pa-10 text-center">
      <v-progress-circular size="50" indeterminate width="1" />
    </div>
    <div v-else>
      <!-- <div v-if="!query">Placeholder</div> -->
      <v-list v-if="recentSearches.length" density="compact">
        <v-list-subheader>Recent searches</v-list-subheader>
        <v-list-item
          v-for="(q, i) in recentSearches"
          :key="`recent-${i}`"
          @click="search(q)"
        >
          <template #prepend>
            <v-icon size="small">history</v-icon>
          </template>

          <v-list-item-title>{{ q }}</v-list-item-title>
        </v-list-item>
      </v-list>

      <v-list v-if="topSearches.length" density="compact">
        <v-list-subheader>Top searches</v-list-subheader>
        <v-list-item
          v-for="(q, i) in topSearches"
          :key="`top-${i}`"
          @click="search(q)"
        >
          <template #prepend>
            <v-icon size="small">search</v-icon>
          </template>

          <v-list-item-title>{{ q }}</v-list-item-title>
        </v-list-item>
      </v-list>

      <!--
      <v-list density="compact">
        <v-list-subheader>Top categories</v-list-subheader>
        <v-list-item v-for="(q, i) in topCategories" :key="`cat-${i}`"  @click="search(q)">
          <template #prepend>
            <v-icon size="small">insert_emoticon</v-icon>
          </template>

          <v-list-item-title>{{ q }}</v-list-item-title>
        </v-list-item>
      </v-list> -->
    </div>
  </div>
</template>

<script lang="ts">
import type { Hits } from "meilisearch";
import { defineComponent, type PropType } from "vue";
import type { SearchCategory } from "./lib";
import type { SearchResult } from "./models";
import SearchContentItem from "./SearchContentItem";

export default defineComponent({
  name: "SearchContent",
  components: { SearchContentItem },
  props: {
    hits: { type: Array as PropType<Hits<SearchResult>>, required: true },
    query: { type: String },
    preset: {
      type: String as PropType<SearchCategory>,
      required: true,
    },
    search: {
      type: Function as PropType<{ (query: string): void }>,
      required: true,
    },
    recentSearches: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    // const recentSearches = ["African event", "Nigeria communi", "Hair sha"];
    // const topSearches = [
    //   "New year festival",
    //   "Hair brush",
    //   "Palm wine",
    //   "NASA community",
    // ];
    // const topCategories = ["Clothing & Fashion", "Home decor", "Groceries"];
    const topSearches: string[] = [];

    return {
      // recentSearches,
      // topCategories,
      topSearches,
    };
  },
  methods: {
    plurable,
  },
});
</script>
